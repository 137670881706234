/* eslint-disable */
import { loadReducers, loadSagaTasks } from '@moonshot-team/saga-toolkit';
import createReduxWaitForMiddleware from '@moonshot-team/saga-toolkit/dist/redux-wait-for-action';
import { all } from '@redux-saga/core/effects';
import { configureStore } from '@reduxjs/toolkit';
import { createRouterMiddleware, initialRouterState, routerReducer } from 'connected-next-router';
import Router from 'next/router';
import { useMemo } from 'react';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import * as modules from './modules';

export const initStore = (preloadedState: any = {}) => {
  const { asPath } = Router.router || {};
  if (asPath) {
    preloadedState.router = initialRouterState(asPath);
  }

  const routerMiddleware = createRouterMiddleware();
  const sagaMiddleware = createSagaMiddleware();

  const reducers = {
    ...loadReducers(modules, preloadedState),
    router: routerReducer,
  };
  const rootReducer = combineReducers(reducers);
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: preloadedState,
    middleware: [ sagaMiddleware, createReduxWaitForMiddleware(), routerMiddleware ],
  });

  const tasks = loadSagaTasks(modules);
  function* rootSaga() {
    yield all([ ...tasks ]);
  }
  sagaMiddleware.run(rootSaga);
  return store;
};

let store: any;

export const initializeStore = (preloadedState: any) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store;
  }

  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
};

export function useStore(initialState: any) {
  const store = useMemo(() => initializeStore(initialState), [ initialState ]);
  return store;
}
