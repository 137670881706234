/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { NftBidOffset, NftBidOffsetInterface } from "../NftBidOffset";

const _abi = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      {
        type: "address",
        name: "adminRouter_",
        internalType: "address",
      },
    ],
  },
  {
    type: "event",
    name: "SetAdmin",
    inputs: [
      {
        type: "address",
        name: "oldAdmin",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newAdmin",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "caller",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "SetBitOffset",
    inputs: [
      {
        type: "address",
        name: "nftTokenAddress",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "bitOffset",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "string",
        name: "",
        internalType: "string",
      },
    ],
    name: "PROJECT",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IAdminProjectRouter",
      },
    ],
    name: "adminRouter",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "batchSetBitOffset",
    inputs: [
      {
        type: "address[]",
        name: "_nftTokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_bitOffset",
        internalType: "uint256[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "bitOffset",
    inputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setAdmin",
    inputs: [
      {
        type: "address",
        name: "_adminRouter",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setBitOffset",
    inputs: [
      {
        type: "address",
        name: "_nftTokenAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_bitOffset",
        internalType: "uint256",
      },
    ],
  },
] as const;

export class NftBidOffset__factory {
  static readonly abi = _abi;
  static createInterface(): NftBidOffsetInterface {
    return new utils.Interface(_abi) as NftBidOffsetInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): NftBidOffset {
    return new Contract(address, _abi, signerOrProvider) as NftBidOffset;
  }
}
