/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  NftRedeemV3Stamp,
  NftRedeemV3StampInterface,
} from "../NftRedeemV3Stamp";

const _abi = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      {
        type: "address",
        name: "nftBitOffset_",
        internalType: "address",
      },
      {
        type: "address",
        name: "nftTransferRouter_",
        internalType: "address",
      },
      {
        type: "address",
        name: "adminRouter_",
        internalType: "address",
      },
      {
        type: "address",
        name: "toBurnAddress_",
        internalType: "address",
      },
    ],
  },
  {
    type: "event",
    name: "SetAdmin",
    inputs: [
      {
        type: "address",
        name: "fromAdmin",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "toAdmin",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "caller",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "SetCallHelper",
    inputs: [
      {
        type: "address",
        name: "fromCallHelper",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "toCallHelper",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "caller",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "SetMaxStampOfNftType",
    inputs: [
      {
        type: "address",
        name: "tokenAddress",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "typeId",
        internalType: "uint256",
        indexed: true,
      },
      {
        type: "uint256",
        name: "maxStamp",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "SetNftRedeemInfo",
    inputs: [
      {
        type: "address",
        name: "redeemAddress",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "redeemId",
        internalType: "uint256",
        indexed: true,
      },
      {
        type: "uint256",
        name: "maxStampPerNftId",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "waitingTimeForNextStamp",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "numOfStampPerUserToActivatedWaitingTime",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "bool",
        name: "isBurnNft",
        internalType: "bool",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Stamp",
    inputs: [
      {
        type: "address",
        name: "tokenAddress",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "tokenId",
        internalType: "uint256",
        indexed: true,
      },
      {
        type: "address",
        name: "userAddress",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "redeemAddress",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "redeemId",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "addAddress",
    inputs: [
      {
        type: "address",
        name: "_addr",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IAdminProjectRouter",
      },
    ],
    name: "adminRouter",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "allowedAddrByIndex",
    inputs: [
      {
        type: "uint256",
        name: "_index",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address[]",
        name: "",
        internalType: "address[]",
      },
    ],
    name: "allowedAddrByPage",
    inputs: [
      {
        type: "uint256",
        name: "_page",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_limit",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "allowedAddrLength",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "",
        internalType: "uint256[]",
      },
    ],
    name: "batchGetCurrentStampOfNftId",
    inputs: [
      {
        type: "address[]",
        name: "_tokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_tokenId",
        internalType: "uint256[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "",
        internalType: "uint256[]",
      },
    ],
    name: "batchGetCurrentStampOfNftIdByRedeem",
    inputs: [
      {
        type: "address[]",
        name: "_tokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_tokenId",
        internalType: "uint256[]",
      },
      {
        type: "address[]",
        name: "_redeemAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_redeemId",
        internalType: "uint256[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "",
        internalType: "uint256[]",
      },
    ],
    name: "batchGetMaxStampOfNftId",
    inputs: [
      {
        type: "address[]",
        name: "_tokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_tokenId",
        internalType: "uint256[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "",
        internalType: "uint256[]",
      },
    ],
    name: "batchGetTempCurrentStampOfNftIdByRedeemByUser",
    inputs: [
      {
        type: "address[]",
        name: "_tokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_tokenId",
        internalType: "uint256[]",
      },
      {
        type: "address[]",
        name: "_redeemAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_redeemId",
        internalType: "uint256[]",
      },
      {
        type: "address[]",
        name: "_userAddress",
        internalType: "address[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "",
        internalType: "uint256[]",
      },
    ],
    name: "batchGetTimestampOfNftIdByRedeemByUser",
    inputs: [
      {
        type: "address[]",
        name: "_tokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_tokenId",
        internalType: "uint256[]",
      },
      {
        type: "address[]",
        name: "_redeemAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_redeemId",
        internalType: "uint256[]",
      },
      {
        type: "address[]",
        name: "_userAddress",
        internalType: "address[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "batchSetMaxStampOfNftType",
    inputs: [
      {
        type: "address[]",
        name: "_tokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_typeId",
        internalType: "uint256[]",
      },
      {
        type: "uint256[]",
        name: "_maxStamp",
        internalType: "uint256[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "callHelper",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "",
        internalType: "uint256[]",
      },
    ],
    name: "checkCanRedeem",
    inputs: [
      {
        type: "address",
        name: "_redeemAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_redeemId",
        internalType: "uint256",
      },
      {
        type: "address",
        name: "_userAddress",
        internalType: "address",
      },
      {
        type: "address[]",
        name: "_tokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_tokenId",
        internalType: "uint256[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getCurrentStampOfNftId",
    inputs: [
      {
        type: "address",
        name: "_tokenAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_tokenId",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getCurrentStampOfNftIdByRedeem",
    inputs: [
      {
        type: "address",
        name: "_tokenAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_tokenId",
        internalType: "uint256",
      },
      {
        type: "address",
        name: "_redeemAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_redeemId",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getMaxStampOfNftId",
    inputs: [
      {
        type: "address",
        name: "_tokenAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_tokenId",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getTempCurrentStampOfNftIdByRedeemByUser",
    inputs: [
      {
        type: "address",
        name: "_tokenAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_tokenId",
        internalType: "uint256",
      },
      {
        type: "address",
        name: "_redeemAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_redeemId",
        internalType: "uint256",
      },
      {
        type: "address",
        name: "_userAddress",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getTimestampOfNftIdByRedeemByUser",
    inputs: [
      {
        type: "address",
        name: "_tokenAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_tokenId",
        internalType: "uint256",
      },
      {
        type: "address",
        name: "_redeemAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_redeemId",
        internalType: "uint256",
      },
      {
        type: "address",
        name: "_userAddress",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "bool",
        name: "",
        internalType: "bool",
      },
    ],
    name: "isAllowedAddr",
    inputs: [
      {
        type: "address",
        name: "_addr",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "maxStampOfNftType",
    inputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract INftBitOffset",
      },
    ],
    name: "nftBitOffset",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "maxStampPerNftIdByRedeem",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "waitingTimeForNextStampByRedeem",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "numOfStampPerUserToActivatedWaitingTimeByRedeem",
        internalType: "uint256",
      },
      {
        type: "bool",
        name: "isBurnNft",
        internalType: "bool",
      },
    ],
    name: "nftRedeemInfoMapping",
    inputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract INftTransferRouter",
      },
    ],
    name: "nftTransferRouter",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "string",
        name: "",
        internalType: "string",
      },
    ],
    name: "project",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "revokeAddress",
    inputs: [
      {
        type: "address",
        name: "_addr",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setAdmin",
    inputs: [
      {
        type: "address",
        name: "_adminRouter",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setCallHelper",
    inputs: [
      {
        type: "address",
        name: "_callHelper",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setMaxStampOfNftType",
    inputs: [
      {
        type: "address",
        name: "_tokenAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_typeId",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_maxStamp",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setNftBitOffset",
    inputs: [
      {
        type: "address",
        name: "_nftBitOffset",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setNftRedeemInfo",
    inputs: [
      {
        type: "address",
        name: "_redeemAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_redeemId",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_maxStamp",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_waitingTimeForNextStamp",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_numOfStampPerUserToActivatedWaitingTime",
        internalType: "uint256",
      },
      {
        type: "bool",
        name: "_isBurnNft",
        internalType: "bool",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setNftTransferRouter",
    inputs: [
      {
        type: "address",
        name: "_nftTransferRouter",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setToBurnAddr",
    inputs: [
      {
        type: "address",
        name: "_toBurnAddress",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "stamp",
    inputs: [
      {
        type: "address",
        name: "_redeemAddress",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_redeemId",
        internalType: "uint256",
      },
      {
        type: "address",
        name: "_userAddress",
        internalType: "address",
      },
      {
        type: "address[]",
        name: "_tokenAddress",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_tokenId",
        internalType: "uint256[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "toBurnAddress",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "pure",
    outputs: [
      {
        type: "string",
        name: "",
        internalType: "string",
      },
    ],
    name: "typeAndVersion",
    inputs: [],
  },
] as const;

export class NftRedeemV3Stamp__factory {
  static readonly abi = _abi;
  static createInterface(): NftRedeemV3StampInterface {
    return new utils.Interface(_abi) as NftRedeemV3StampInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): NftRedeemV3Stamp {
    return new Contract(address, _abi, signerOrProvider) as NftRedeemV3Stamp;
  }
}
