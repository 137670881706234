import { gql } from '@apollo/client';

export const RedemptionV3DaoListByIdQuery = (options: { id: string; offset: number; limit: number }) => {
  const defaultPaging = `paging: { offset: ${ options.offset }, limit: ${ options.limit } }`;
  const defaultFilters = `
        nft_id: { eq: "${ options.id }" },
    `;

  const conditions = `
        filter: { 
            ${ defaultFilters }, 
        }
        ${ defaultPaging }
        sorting: { field: end_time, direction: DESC }
    `;

  return gql`
        query {
            nftAvailableSocialDaos(${ conditions }) {
                nodes {
                    id
                    name
                    thumbnail
                    end_time
                    kap_721_contract_address_with_type_id
                }
            }
        }
    `;
};
