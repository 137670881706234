import type { Feature } from './types';

import { getEnvValue } from '../utils';

const id = getEnvValue('NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID');

const title = 'Google Tag Manager';

const config: Feature<{ id: string }> = (() => {
  if (id) {
    return Object.freeze({
      title,
      isEnabled: true,
      id,
    });
  }

  return Object.freeze({
    title,
    isEnabled: false,
  });
})();

export default config;
