import { gql } from '@apollo/client';

import { RedemptionV3Status } from '../../../modules/nft-redemption-v3/types';

export const RedemptionV3ListByNftContractTypeIdQuery = (options: {
  nftContractWithTypeIdKey: string;
  status: RedemptionV3Status;
  offset: number;
  limit: number;
}) => {
  const now = Math.round(new Date().getTime() / 1000);
  const defaultPaging = `paging: { offset: ${ options.offset }, limit: ${ options.limit } }`;
  const defaultFilters = `
        contract_type_id: { eq: "${ options.nftContractWithTypeIdKey }" },
        start_time: { lte: ${ now } }
    `;

  let conditions = '';
  switch (options.status) {
    case RedemptionV3Status.Complete:
      conditions = `
                filter: { 
                    ${ defaultFilters }, 
                    status: { eq: "COMPLETE" } 
                }
                ${ defaultPaging }
                sorting: { field: created_time, direction: DESC }
            `;
      break;
    case RedemptionV3Status.EndTime:
      conditions = `
                filter: { 
                    ${ defaultFilters }, 
                    status: { eq: "OPEN" },
                    end_time: { lte: ${ now } }
                }
                ${ defaultPaging }
                sorting: { field: created_time, direction: DESC }
            `;
      break;
    case RedemptionV3Status.Open:
    default:
      conditions = `
                filter: { 
                    ${ defaultFilters }, 
                    status: { eq: "OPEN" }, 
                    end_time: { gte: ${ now } } 
                }
                ${ defaultPaging }
                sorting: {
                    field: end_time,
                    direction: ASC
                }
            `;
      break;
  }

  return gql`
        query {
            redemptionV3s(${ conditions }) {
                nodes {
                    id
                    name
                    description
                    image
                    platform
                    status
                    end_time
                    max_stamp_per_nft_id
                    total_used
                    total_supply
                    redemption_type
                }
            }
        }
    `;
};
