/* eslint-disable */
import { createReduxAsyncTask } from '@moonshot-team/saga-toolkit';
import { graphqlNftRedemptionV3GraphqlClient } from 'shared-modules/graphql/clients';
import { RedemptionV3ListByNftContractTypeIdQuery } from 'shared-modules/graphql/query/redemption-v3/redemption-v3-list-by-type-id';

import { MODULE_NAME, RedemptionV3DaoListDto, RedemptionV3HistoryListDto, RedemptionV3Status } from './types'
import type { RedemptionV3ListDto } from './types';
import { getNftTypeId } from 'shared-modules/helpers/get-nft-type-id'
import { getRpcProvider } from 'shared-modules/helpers/ethers-config'
import { NftRedeemV3Stamp__factory } from 'shared-modules/contracts/typings'
import { BigNumber } from 'ethers'
import { put } from 'redux-saga/effects'
import { RedemptionV3DaoListByIdQuery } from 'shared-modules/graphql/query/redemption-v3/redemption-v3-dao-list-by-id'
import { contractAddressMapping } from 'shared-modules/constants/contract-address'
import { RedemptionV3HistoryDetailByTokenIdQuery } from 'shared-modules/graphql/query/redemption-v3/redemption-v3-history-detail-by-token-id'

const DEFAULT_LIMIT = 100
const DEFAULT_OFFSET = 0

export const getRedemptionV3ListByTokenIdTask = createReduxAsyncTask({
  moduleName: MODULE_NAME,
  name: 'getRedemptionV3ListByTokenId',
  defaultPayload: {} as { nftContract: string; tokenId: string; },
  defaultData: [] as Array<RedemptionV3ListDto>,
  saga: ({ actions }) =>
    function* ({ payload }) {
      try {
        const { nftContract, tokenId } = payload

        const typeId: string = yield getNftTypeId(nftContract, tokenId)

        if (!typeId) {
          throw new Error('getRedemptionV3ListByTokenIdTask : Type Id Not Found');
        }

        const typeIdKey = `${ nftContract }_${ typeId }`.toLowerCase();

        const {
          data: {
            redemptionV3s: { nodes: result = [] },
          },
        }: { data: { redemptionV3s: { nodes: Array<RedemptionV3ListDto> } } } =
                    yield graphqlNftRedemptionV3GraphqlClient.query<Array<RedemptionV3ListDto>>({
                      query: RedemptionV3ListByNftContractTypeIdQuery({
                        nftContractWithTypeIdKey: typeIdKey,
                        status: RedemptionV3Status.Open,
                        offset: DEFAULT_OFFSET,
                        limit: DEFAULT_LIMIT,
                      }),
                      fetchPolicy: 'no-cache',
                    });

        if (result.length > 0) {
            const redeemAddress: string[] = []
            const redeemIds: string[] = []

            for (const graphListData of result) {
                const redeemData = graphListData.id.split('_')
                redeemAddress.push(redeemData[0])
                redeemIds.push(redeemData[1])
            }

            // create array length nft address & nft token depend on redeem item
            const nftAddress = new Array(result.length).fill(nftContract)
            const nftToken = new Array(result.length).fill(tokenId)

            // call contract to get current stamp
            const { jsonRpcProvider } = getRpcProvider()

            const nftRedeemV3StampContract = NftRedeemV3Stamp__factory.connect(
                contractAddressMapping.NFT_REDEEM_V3_STAMP,
                jsonRpcProvider,
            )

            const nftRedeemStampData: BigNumber[] = yield nftRedeemV3StampContract.batchGetCurrentStampOfNftIdByRedeem(
                nftAddress,
                nftToken,
                redeemAddress,
                redeemIds,
            )

            for (let index = 0; index < result.length; index++) {
                const redemptionV3List = result[index]

                redemptionV3List.currentStamp = nftRedeemStampData[index].toNumber()
            }
        }

        yield put(actions.success(result))
      } catch (error) {
        yield put(actions.failure(error))
      }
    },
});


export const getRedemptionV3DaoListByIdTask = createReduxAsyncTask({
    moduleName: MODULE_NAME,
    name: 'getRedemptionV3DaoListById',
    defaultPayload: {} as { nftContract: string; tokenId: string; },
    defaultData: [] as RedemptionV3DaoListDto[],
    saga: ({ actions }) =>
        function* ({ payload }) {
            try {
                const { nftContract, tokenId } = payload

                const typeId: string = yield getNftTypeId(nftContract, tokenId)

                const typeIdKey = `${nftContract}_${typeId}`.toLowerCase()

                const {
                    data: {
                        nftAvailableSocialDaos: { nodes: result = [] },
                    },
                }: { data: { nftAvailableSocialDaos: { nodes: RedemptionV3DaoListDto[] } } } =
                    yield graphqlNftRedemptionV3GraphqlClient.query<RedemptionV3DaoListDto[]>({
                        query: RedemptionV3DaoListByIdQuery({
                            id: typeIdKey,
                            offset: DEFAULT_OFFSET,
                            limit: DEFAULT_LIMIT,
                        }),
                        fetchPolicy: 'no-cache',
                    })

                if (result.length > 0) {
                    const redeemAddress: string[] = []
                    const redeemIds: string[] = []

                    for (const graphListData of result) {
                        const redeemData = graphListData.id.split('_')
                        redeemAddress.push(redeemData[0])
                        redeemIds.push(redeemData[1])
                    }
                }

                yield put(actions.success(result))
            } catch (error) {
                yield put(actions.failure(error))
            }
        },
})


export const getRedemptionV3HistoryListByTokenIdTask = createReduxAsyncTask({
    moduleName: MODULE_NAME,
    name: 'getRedemptionV3HistoryListByTokenId',
    defaultPayload: {} as { nftContract: string; tokenId: string; },
    defaultData: [] as Array<RedemptionV3HistoryListDto>,
    saga: ({ actions }) =>
        function* ({ payload }) {
            try {
                const { nftContract, tokenId } = payload

                const {
                    data: {
                        redemptionV3Stamps: { nodes: result = [] },
                    },
                }: { data: { redemptionV3Stamps: { nodes: Array<RedemptionV3HistoryListDto> } } } =
                    yield graphqlNftRedemptionV3GraphqlClient.query<Array<RedemptionV3HistoryListDto>>({
                        query: RedemptionV3HistoryDetailByTokenIdQuery(
                            nftContract,
                            tokenId,
                        ),
                        fetchPolicy: 'no-cache',
                    });

                yield put(actions.success(result))
            } catch (error) {
                console.log('error', error)
                yield put(actions.failure(error))
            }
        },
});
