export const MODULE_NAME = '@shared-modules:nft-redemption-v3';

export enum RedemptionV3Status {
  Open = 'OPEN',
  Complete = 'COMPLETE',
  EndTime = 'END_TIME',
}

export interface RedemptionV3ListDto {
  id: string;
  name: string;
  description: string;
  image: string;
  status: RedemptionV3Status;
  end_time: number;
  max_stamp_per_nft_id: number;
  total_used: number;
  total_supply: number;
  platform: string;
  currentStamp: number;
  redemption_type: RedemptionType;
}

export interface RedemptionV3HistoryListDto {
  id: string;
  block_timestamp: number;
  kap721_contract_address: string;
  nft_id: string;
  redemption_address: string;
  redemption_id: string;
  redemption_number: number;
  redemption_type: RedemptionType;
  token_id: number;
  wallet_address: string;
  redemption_name: string;
}

export enum RedemptionType {
  NFTKap20 = 'NFTKap20',
  NFTKap721 = 'NFTKap721',
  NFTPhysical = 'NFTPhysical',
  NFTVoucher = 'NFTVoucher',
  KUBVoucher = 'KUBVoucher',
}

export interface RedemptionV3DaoListDto {
  id: string;
  name: string;
  thumbnail: string;
  end_time: number;
  kap_721_contract_address_with_type_id: string;
}

export const mappingRedemptionType = (redemptionType: RedemptionType) => {
  switch (redemptionType) {
    case RedemptionType.NFTPhysical: return 'Physical';
    default: return 'Digital';
  }
};
