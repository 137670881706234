import { ethers } from 'ethers';
import { contractAddressMapping } from 'shared-modules/constants/contract-address';
import { ContractAddressName } from 'shared-modules/constants/enums/contract-address-name';
import { NftBidOffset__factory } from 'shared-modules/contracts/typings';
import { getRpcProvider } from 'shared-modules/helpers/ethers-config';

const bidOffsetByContractAddressCache: { [nftContractAddress: string]: number } = {};

export const getNftTypeId = async(nftContractAddress: string, tokenId: string): Promise<string> => {
  let bidOffset: number;

  // check bid offset cache
  try {
    const { jsonRpcProvider } = getRpcProvider();
    const nftBidOffsetContract = NftBidOffset__factory.connect(contractAddressMapping[ContractAddressName.NftBidOffset] as string, jsonRpcProvider);
    const response = await nftBidOffsetContract.bitOffset(nftContractAddress);
    bidOffset = response.toNumber();

    bidOffsetByContractAddressCache[nftContractAddress.toLowerCase()] = bidOffset;
  } catch {
    bidOffset = bidOffsetByContractAddressCache[nftContractAddress.toLowerCase()];
  }

  if (bidOffset === 0) {
    // always default bid offset 17
    bidOffset = 17;
  }

  return ethers.BigNumber.from(tokenId).shr(bidOffset).shl(bidOffset).toString();
};
