import { gql } from '@apollo/client';

export const RedemptionV3HistoryDetailByTokenIdQuery = (contractAddress: string, tokenId: string) => {
  return gql`
        query {
            redemptionV3Stamps(
                filter: { 
                    kap721_contract_address: { eq: "${ contractAddress }" }
                    token_id: { eq: ${ tokenId } }
                },
                sorting: {
                    field: block_timestamp,
                    direction: DESC
                },
                paging: {
                    limit: 300
                }
            ) {
                nodes {
                    id
                    block_timestamp
                    kap721_contract_address
                    nft_id
                    redemption_address
                    redemption_id
                    redemption_number
                    redemption_type
                    token_id
                    wallet_address
                    redemption_name
                }
            }
        }
    `;
};
