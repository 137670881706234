import { ethers } from 'ethers';

import { getEnvValue } from 'configs/app/utils';

export const getRpcProvider = () => {
  return {
    jsonRpcProvider: new ethers.providers.JsonRpcProvider(
      getEnvValue('NEXT_PUBLIC_NETWORK_RPC_URL'),
      getEnvValue('NEXT_PUBLIC_CHAIN_RPC_NETWORK_ID'),
    ),
    jsonRpcBatchProvider: new ethers.providers.JsonRpcBatchProvider(
      getEnvValue('NEXT_PUBLIC_NETWORK_RPC_URL'),
      getEnvValue('NEXT_PUBLIC_CHAIN_RPC_NETWORK_ID'),
    ),
  };
};
