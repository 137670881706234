import React from 'react';

import config from 'configs/app';

const feature = config.features.cookieWow;

const CookieWow = () => {
  if (!feature.isEnabled) {
    return null;
  }

  const id = feature.id.toString();

  return (
    <>
      <script async src="https://cookiecdn.com/cwc.js" type="text/javascript"/>
      <script
        async
        data-cwcid={ id }
        id="cookieWow"
        src={ `https://cookiecdn.com/configs/${ id }` }
        type="text/javascript"
      />
    </>
  );
};

export default React.memo(CookieWow);
