import { createReduxModule } from '@moonshot-team/saga-toolkit';

import { MODULE_NAME } from './types';

import * as tasks from './tasks';

export const nftRedemptionV3Module = createReduxModule({
  moduleName: MODULE_NAME,
  initialState: {},
  tasks,
});
